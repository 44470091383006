<template>
  <section class="hero is-dark">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">Privacy Policy</h1>
        <hr />
        <p>Last update on March 5th, 2022.</p>
        <p>By using our Services, you agree that you have read and agree to this policy.</p>
        <p>If you have any questions or requests regarding this Privacy Policy, you can contact us here: <a href="mailto:privacy@crni.xyz">privacy@crni.xyz</a>.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes to this Privacy Policy they are effective when posted on this page.</p>
        <h1 class="title is-5 block">Terminology</h1>
        <div class="content">
          <ul>
            <li>Server Manager - Anyone who has the ability to add a bot to a server or configure the bot for the server.</li>
            <li>Server Member - Anyone who is a member of server to which bot has been added and it's regularly using.</li>
            <li>Service User - Anyone who authorizes an application for a scope that provides additional data.</li>

          </ul>
        </div>
        <h1 class="title is-5 block">Data Collected By Command</h1>
        <div class="content">
          <p>TThe following information may be collected and stored when intentionally provided by a user (usually by means of a command). This data will not be collected automatically.</p>
          <ul>
            <li>User data like name, ID, avatar.</li>
            <li>Channel ID's, Bot ID's, Server ID's.</li>
            <li>Text input for embedded configurations.</li>
          </ul>
        </div>
        <h1 class="title is-5 block">Data Collected When Enabled</h1>
        <div class="content">
          <p>These items will be automatically collected if a bot is configured to perform certain actions by a server administrator. These features are always opt-in. This data may be collected automatically. This data is used to provide statistics or history data.</p>
          <ul>
            <li>Avatar data.</li>
            <li>Bot name, presences.</li>
            <li>Data Collected Automatically.</li>
          </ul>
        </div>
        <h1 class="title is-5 block">Data Collected Automatically</h1>
        <div class="content">
          <p>This data may be collected automatically. This data is used to provide statistics or history data. </p>
          <ul>
            <li>Any data needed for standard operation of Discord bots, such as server permissions. This data is stored in cache only while the bot is running.</li>
          </ul>
        </div>
        <h1 class="title is-5 block">How We Use Your Data</h1>
        <div class="content">
          <ul>
            <li>Your user data (such as ID) can be used for analytics of bot's monthly or longer usage.</li>
            <li>Note: We DO NOT store any of your message data but rather the message ID's of the bot messages which we have no means to utilize ourselves.</li>
            <li>We reserve the right to disclose your data in the good faith belief that such action is necessary to comply with a legal obligation requested by law enforcement agencies.</li>
          </ul>
        </div>
        <h1 class="title is-5 block">Minor's Privacy</h1>
        <div class="content">
          <ul>
            <li>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13 as its not allowed per <a href="https://discord.com/terms">Discord Terms of Service</a> as well.</li>
            <li>If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact us.</li>
          </ul>
        </div>
        <h1 class="title is-5 block">How Can You Request Data</h1>
        <div class="content">
        <ul>
          <li>If at any point you would like to request the extend of the data we have stored from you you can do so by contacting us.</li>
        </ul>
        </div>
        <h1 class="title is-5 block">How Can You Delete That Data</h1>
        <div class="content">
        <ul>
          <li>Any stored data can be removed automatically by removing our bot from your server. If you would like us to permanently delete all user data set by you, please contact us through Discord <a href="https://discord.com/users/797012765352001557">@The Digital#3999</a> or via email at <a href="mailto:privacy@crni.xyz">privacy@crni.xyz</a>.</li>
        </ul>
        </div>
        <h1 class="title is-5 block">How Long Do We Store Your Data</h1>
        <div class="content">
        <ul>
          <li>Data is stored over a period of one month once a command is used, if a user consistently uses the service the data will be stored as long as the user uses the service and in any case not more than 6 months if the purpose of the storage has diminished.</li>
        </ul>
        </div>
        <h1 class="title is-5 block">Data Security & Storage</h1>
        <div class="content">
          <ul>
            <li>All stored data is kept on protected servers managed by <a href="https://www.mongodb.com/">MongoDB</a> you can see their privacy policy <a href="https://www.mongodb.com/legal/privacy-policy">here</a>. While storage methods vary, most data is kept within password-protected databases.</li>
            <li>Please keep in mind that even with these protections, no data can ever be 100% secure. All efforts are taken to keep your data secure and private, but its absolute security cannot be guaranteed.</li>
          </ul>
        </div>
        <h1 class="title is-5 block">Feedback</h1>
        <div class="content">
          <ul>
            <li>Feedback on any and all provided bots and services is appreciated.</li>
            <li>When you submit comments, suggestions or any other forms of feedback, you forego any rights to the content, title, or intent of the provided feedback. The feedback will be utilized strictly to improve the bot and its services.</li>
          </ul>
        </div>
        <h1 class="title is-5 block">Agreement</h1>
        <div class="content">
          <ul>
            <li>By using <a href="https://discord.com/api/oauth2/authorize?client_id=847180236545327164&permissions=268913664&scope=bot%20applications.commands">Status Bot</a> at your server or as a member in any way, you are consenting to the policy outlined in this document.</li>
            <li>If you at any point decide you don’t agree to this policy you can stop using the service (as a member) or remove the bot from your server (as an administrator), If you, the service user, do not agree to this policy, you may revoke authorization of the application(s) in your 'Authorized Apps' menu.</li>
          </ul>
        </div>
        
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data(){
    return {
      popupActivo:false,
    }
  }
}
</script>

<style scoped lang="scss">
.block {
  margin-top: 2.5rem !important;
}

p,
ul {
  color: $grey;
}
a:link, a:visited {
  text-decoration: underline;
}
</style>
